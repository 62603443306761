* {
    margin: 0;
    padding: 0;
}

.studioDiv {
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url('../assets/grad_bckgrnd.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.studio_background {
    height: 44vh;
    width: 100%;
    position: relative;
    z-index: 9;
    object-fit: cover;
    object-position: bottom;
}

.studioBack {
    position: relative;
}

.studioBack .studioback_overlay {
    position: absolute;
    width: 100%;
    height: 99%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, #000000 100%);
    top: 0;
    left: 0;
    z-index: 10;
}

.studioBack_inner {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 12;
}

.studioBack_inner h2 {
    color: white;
    padding-bottom: 10px;
}

.studioBack_inner p {
  color: white;
  font-size: 14px;
  width: 80%;
  padding-bottom: 12px;
}

.studioBack_inner .svg {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.7;
  }
  
  .studioBack_inner .studio_div {
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
  }
  
  .studioBack_inner .studio_div .span {
    color: white;
    font-size: 12px;
  }
  
  .studioBack_inner .studio_div .studio {
    display: flex;
    align-items: center;
    gap: 6px
  }
  
  .studioBack_inner .studio_div .studio img {
    width: 35px;
    height: 35px;
  }
  
  .studioBack_inner .studio_div .studio span {
    color: white;
    font-weight: 600;
  }

.studio_followbtn {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 51px;
    padding: 12px 20px;
    color: white;
    border: none;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.studio_bottom {
    background: black;
    padding: 20px 20px 30px;
    margin-top: -6px;
    position: relative;
    z-index: 20;
    border-radius: 0px 0px 30px 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.studio_stats {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.studio_stat {
    text-align: center;
}

.studio_stat b {
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.studio_stat h4 {
    color: white;
    font-weight: 400;
    font-size: 12px;

}

.studio_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;
}

.studio_socialfriends {
    padding: 0 10px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.studio_socialfriends img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    object-position: center;
    margin-left: -20px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.studio_social p {
    color: white;
    font-size: 14px;
}

.studio_carousel {
    position: relative;
    z-index: 9;
    padding: 35px 0 0;
    background-image: url('../assets/grad_bckgrnd.png');
    background-size: cover;
    background-repeat: no-repeat;
}

/*****************  DESKTOP  *****************/

.studio_D_Div {
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url('../assets/desktop_back.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.studio_D_Hero {
    min-height: calc(var(--vh, 1vh) * 72);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 13.42%, #000000 100%), url('../assets/studio_back.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding: 0 10vw 6vh;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.studio_D_Hero h2 {
    font-weight: 700;
    font-size: 100px;
    line-height: 100%;
}

.studio_D_Hero p {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
}

.studio_D_Bottom {
    background: #000000;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 50px 50px;
    padding: 10px 10vw 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.studio_D_Stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.studio_D_Stat {
    color: white;
    text-align: center;
}

.studio_D_Stat b {
    font-weight: 600;
    font-size: 24px;
}

.studio_D_Stat h4 {
    font-weight: 400;
    font-size: 16px;
}

.studio_D_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.studio_D_Friends {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.studio_D_Friends img {
    width: 50px;
    height: 50px;
    margin-left: -20px;
    border-radius: 50px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
}

.studio_D_social p {
    color: white;
    font-size: 15px;
    line-height: 120%;
}

.studio_D_Div .studio_D_DashDiv_SplideDiv {
    aspect-ratio: 3/2;
    width: 30vw;
    display: flex;
    flex-direction: column;
    background-position: center !important;
    background-size: cover !important;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 16px;
    color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.studio_D_Div .user_D_DashDiv_SplideDiv_Tag {
    margin: 0 4px 0 0 !important;
}

.Home_Tags_Follow {
    font-weight: 700 !important;
    padding: 15px 30px;
    border: none;
    color: white;
    font-size: 14px !important;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.Home_Tags_Follow:hover {
    background: rgba(255, 255, 255, 0.3);
}

.Home_Tags_Following {
    font-weight: 700 !important;
    padding: 15px 30px;
    border: none;
    font-size: 14px !important;
    background: rgba(255, 255, 255, 1);
    color: black;
    cursor: pointer;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}