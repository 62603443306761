.str3mlogo {
    width: 250px;
  }
  
  
  .onboard_div {
    width: 100vw;
    height: 100svh;
    background-image: url('../assets/grad_bckgrnd.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 9999;
    display: flex;
    justify-content: center;
  }
  
  .onboard_div_disabled {
    display: none;
  }
  
  .onboard_inner {
    /* background-image: url('../assets/grad_bckgrnd.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    opacity: 0;
  }
  
  .ob_active {
    display: flex;
    opacity: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  
  .ob_image {
    width: 70%;
    padding-bottom: 10vh;
  }
  
  .onboard_bottom {
    background: #0A0A0C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    align-items: center;
    height: 100svh;
    max-height: 240px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 20px 40px;
    color: white;
    text-align: center;
  }
  
  .onboard_bottom h2 {
    font-size: 35px;
    line-height: 100%;
    margin: 0
  }
  
  .onboard_bottom p {
    font-size: 16px;
    line-height: 130%;
    padding-top: 10px;
    padding-bottom: 20px;
    margin: 0
  }
  
  .onboard_button {
    cursor: pointer;
    height: 50px;
    width: 100px;
    background: linear-gradient(91.32deg, #642B73 0%, #C6426E 100%);
    border-radius: 84px;
    border: none;
    color: white;
    position: relative;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  .onboard_button:hover {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}

  .onboard_button_back {
    cursor: pointer;
    height: 50px;
    width: 100px;
    background: transparent;
    border-radius: 84px;
    border: none;
    color: white;
    position: relative;

  }
  
  .ob_svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
  
  #ob_one {
    z-index: 10;
  }
  
  #ob_two {
    z-index: 9;
  }
  
  #ob_three {
    z-index: 8;
  }
  
  #ob_four {
    z-index: 7;
  }

  @media only screen and (max-width: 768px) {
    .onboard_bottom h2 {
      font-size: 28px;
    }
    .onboard_bottom p {
      font-size: 14px;
    }
  }