.bottomBar {
    position: fixed;
    width: 100vw;
    height: 60px;
    background-color: rgba(0,0,0,0.8);
    z-index: 999;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    backdrop-filter: blur(6px);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.bottomBar img {
    width: 28px;
    height: 28px;
}