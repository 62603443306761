@keyframes starKeyframe {
  25% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}  
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}  
  75% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}  
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}  
}

* {
    margin: 0;
    padding: 0;
  }
  
  .tags {
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
  }
  
  .tag {
    padding: 7px 10px;
    background: rgba(255,255,255,0.2);
    color: rgba(255,255,255,0.8);
    font-weight: 800;
    font-size: 10px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
  }
  
  .root, .AppGI {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    scroll-snap-align: start;
  }
  
  .AppGI video {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
    position: relative;
    z-index: 2;
  }
  
  .AppGIInactive {
    position: relative;
  }
  
  .comingSoon {
    width: fit-content;
    margin-bottom: 14px;
    color: white;
    font-weight: 800;
    font-size: 14px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
  }
  
  .AppGI img {
    width: 100%;
    object-fit: cover;
    height: calc(var(--vh, 1vh) * 100);
  }
  
  .AppGI .overlay {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0,0,0,0.80) 90%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  
  .AppGI .text {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    z-index: 4;
  }
  
  .AppGI .text h2 {
    color: white;
    padding-bottom: 10px;
  }
  
  .AppGI .text p {
    color: white;
    font-size: 14px;
    width: 80%;
  }
  
  .AppGI .text .svg {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.7;
  }

  .AppGI .text .svgactive {
    animation: starKeyframe 0.6s ease forwards;
    -webkit-animation: starKeyframe 0.6s ease forwards;
}
  
  .AppGI .studio_div {
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .AppGI .studio_div .span {
    color: white;
    font-size: 12px;
  }
  
  .AppGI .studio_div .studio {
    display: flex;
    align-items: center;
    gap: 6px
  }
  
  .AppGI .studio_div .studio img {
    width: 25px;
    height: 25px;
  }
  
  .AppGI .studio_div .studio span {
    color: white;
    font-weight: 500;
    font-size: 14px;
  }

  .studio_followbtn_white {
    line-height: 100%;
    background: white;
    border-radius: 51px;
    padding: 10px 16px;
    color: black;
    border: 0.4px solid white;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
  
  .studio_followbtn_borded {
    line-height: 100%;
    background: transparent;
    border-radius: 51px;
    padding: 10px 16px;
    color: white;
    border: 0.4px solid white;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }