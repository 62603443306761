@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
  touch-action: manipulation;

}

a {
  color: inherit;
}

a:hover {
  color: inherit;

}

h1, h2, h3, h4, h5 {
  margin: 0;
}

iframe {
  display: none !important;
}

body {
  background-image: url(/static/media/grad_bckgrnd.86b69249.png);
  background-position: center;
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: none;
  height: -webkit-fill-available;
  text-rendering: optimizeSpeed;
  position: relative;
  scroll-snap-type: y mandatory;
  place-items: center;
}

#gameroot {
  background-image: url(/static/media/grad_bckgrnd.86b69249.png);
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  z-index: 999;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

#div_background {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

#root {
  height: 100svh;
  background: black;
  position: relative;
  left: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  z-index: 3;
}

#backround_root {
  height: 100svh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.gameroot_active {
  left: -100vw !important;
  z-index: -1 !important;
}

.user_logout {
  position: absolute;
  padding: 4px;
  top: 10px;
  right: 20px;
  z-index: 10;
  background: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

@media only screen and (max-width: 768px) {

  html {
    overflow-y: hidden;
  }

}
@-webkit-keyframes starKeyframe {
  25% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}  
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}  
  75% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}  
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}  
}

@keyframes starKeyframe {
  25% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}  
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}  
  75% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}  
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}  
}

* {
    margin: 0;
    padding: 0;
  }
  
  .tags {
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
  }
  
  .tag {
    padding: 7px 10px;
    background: rgba(255,255,255,0.2);
    color: rgba(255,255,255,0.8);
    font-weight: 800;
    font-size: 10px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
  }
  
  .root, .AppGI {
    width: 100%;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    scroll-snap-align: start;
  }
  
  .AppGI video {
    width: 100%;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
    position: relative;
    z-index: 2;
  }
  
  .AppGIInactive {
    position: relative;
  }
  
  .comingSoon {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 14px;
    color: white;
    font-weight: 800;
    font-size: 14px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
  }
  
  .AppGI img {
    width: 100%;
    object-fit: cover;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
  }
  
  .AppGI .overlay {
    width: 100%;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0,0,0,0.80) 90%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  
  .AppGI .text {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    z-index: 4;
  }
  
  .AppGI .text h2 {
    color: white;
    padding-bottom: 10px;
  }
  
  .AppGI .text p {
    color: white;
    font-size: 14px;
    width: 80%;
  }
  
  .AppGI .text .svg {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.7;
  }

  .AppGI .text .svgactive {
    animation: starKeyframe 0.6s ease forwards;
    -webkit-animation: starKeyframe 0.6s ease forwards;
}
  
  .AppGI .studio_div {
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .AppGI .studio_div .span {
    color: white;
    font-size: 12px;
  }
  
  .AppGI .studio_div .studio {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px
  }
  
  .AppGI .studio_div .studio img {
    width: 25px;
    height: 25px;
  }
  
  .AppGI .studio_div .studio span {
    color: white;
    font-weight: 500;
    font-size: 14px;
  }

  .studio_followbtn_white {
    line-height: 100%;
    background: white;
    border-radius: 51px;
    padding: 10px 16px;
    color: black;
    border: 0.4px solid white;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
  
  .studio_followbtn_borded {
    line-height: 100%;
    background: transparent;
    border-radius: 51px;
    padding: 10px 16px;
    color: white;
    border: 0.4px solid white;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
.bottomBar {
    position: fixed;
    width: 100vw;
    height: 60px;
    background-color: rgba(0,0,0,0.8);
    z-index: 999;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.bottomBar img {
    width: 28px;
    height: 28px;
}
.Home {
    height: 100vh;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    color: white;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    opacity: 1;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.Home_Inner {
    position: relative;
    padding: 0 18vw 6vh;
}
.Home_Studio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    grid-gap: 6px;
    gap: 6px;
}

.Home_Studio img {
    height: 40px;
    width: 40px;
}

.Home_Studio p {
    font-weight: 500 !important;
    font-size: 16px !important;
}

.Home_Inner h2 {
    font-weight: 700;
    font-size: 100px;
    line-height: 100%;
}

.Home_Inner p {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
}

.Home_Tags {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Home_Tags p {
    font-weight: 500;
    font-size: 14px;
    padding: 16px 30px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 35px;
    margin: 0;
}

.Home_Bottom {
    width: 100%;
    padding: 0 6vw;
    position: absolute;
    bottom: 50px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    grid-gap: 6vw;
    gap: 6vw;
}

.Home_Bottom_Nav {
    display: flex;
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
}

.Home_Bottom_Friends {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.Home_Bottom_Friends_Up {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Home_Bottom_Friends_Up p {
    font-weight: 500;
    font-size: 16px;
}

.Home_Bottom_Friends_Down {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Home_Bottom_Friends_Down img {
    width: 65px;
    height: 65px;
    border: 2px solid #30F998;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.Home_Play_Div {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.Home_Svg {
    cursor: pointer;
}

.Home_Play {
    font-weight: 600;
    font-size: 14px;
    color: black;
    padding: 20px 30px;
    background: #FFFFFF;
    border-radius: 15px;
    border: 0;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.Home_Play:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.Home_svg {
    opacity: 1;
    cursor: pointer;
}

.Home_svg_none {
    opacity: 0.4;
    cursor: auto;
}

.Home_disabled {
    display: none;
    opacity: 0;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 20px 6vw;
    z-index: 9999;
}

.header_logo {
    width: 140px;
}

.header_user {
    width: 52px;
    height: 52px;
    border: 2px solid #30F998;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
@-webkit-keyframes starKeyframe {
    25% {
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
  }  
    50% {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
  }  
    75% {
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
  }  
    100% {
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
  }  
  }

  @keyframes starKeyframe {
    25% {
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
  }  
    50% {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
  }  
    75% {
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
  }  
    100% {
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
  }  
  }

  .svg {
    
    cursor: pointer;
  }

.svgactive {
    cursor: pointer;
    animation: starKeyframe 0.6s ease forwards;
    -webkit-animation: starKeyframe 0.6s ease forwards;
}

.gameDiv {
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url(/static/media/grad_bckgrnd.86b69249.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 9;
}

.game_background {
    height: 50vh;
    width: 100%;
    position: relative;
    z-index: 9;
    object-fit: cover;
    object-position: bottom;
}

.gameBack {
    position: relative;
}

.gameBack .gameback_overlay {
    position: absolute;
    width: 100%;
    height: 99%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, #000000 100%);
    top: 0;
    left: 0;
    z-index: 10;
}

.gameBack_inner {
    position: absolute;
    left: 20px;
    bottom: 10px;
    z-index: 12;
}

.gameBack_inner h2 {
    color: white;
    padding-bottom: 10px;
    margin: 0;
}

.gameBack_inner p {
  color: white;
  font-size: 14px;
  width: 90%;
  margin: 0;
}

.gameBack_inner .svg {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.7;
  }
  
  .gameBack_inner .studio_div {
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;
  }
  
  .gameBack_inner .studio_div .span {
    color: white;
    font-size: 12px;
  }
  
  .gameBack_inner .studio_div .studio {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px
  }
  
  .gameBack_inner .studio_div .studio img {
    width: 35px;
    height: 35px;
  }
  
  .gameBack_inner .studio_div .studio span {
    color: white;
    font-weight: 600;
  }

.game_playbtn {
    background: linear-gradient(91.32deg, rgba(100, 43, 115, 0.9) 0%, rgba(198, 66, 110, 0.9) 100%);
    border-radius: 51px;
    padding: 10px 36px;
    color: white;
    border: none;
    font-weight: 500;
    font-size: 14px;
}

.game_bottom {
    background: black;
    padding: 20px 20px 30px;
    margin-top: -6px;
    position: relative;
    z-index: 20;
    border-radius: 0px 0px 30px 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.game_stats {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.game_stat {
    text-align: center;
}

.game_stat b {
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.game_stat h4 {
    color: white;
    font-weight: 400;
    font-size: 12px;

}

.game_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;
}

.game_socialfriends {
    padding: 0 10px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game_socialfriends img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    object-position: center;
    margin-left: -20px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.game_social p {
    color: white;
    font-size: 14px;
}

.game_leaderboard {
    padding: 30px 24px;
}

.game_leaderboard h2 {
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 22px;
}

.game_leaderboard_first {
    padding: 20px 0;
    text-align: center;
}

.game_leaderboard_first_img {
    width: 68px;
    height: 68px;
    border-radius: 70px;
    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    -ms-border-radius: 70px;
    -o-border-radius: 70px;
}

.game_leaderboard_first_usertag {
    font-weight: 300;
    font-size: 14px;
    color: white;
}

.game_leaderboard_first_score {
    font-weight: 600;
    font-size: 18px;
    color: #30F998;

}

.game_leaderboard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 10px;
}

.game_leaderboard_div small {
    font-weight: 600;
    font-size: 16px;
    color: white;
}

.game_leaderboard_div_inner {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
    flex-direction: row;
    background: rgba(217, 217, 217, 0.1);
    border-radius: 49px;
    padding: 14px;

}

.game_leaderboard_div_inner small {
    font-weight: 400;
    font-size: 13px;
    color: white;
}

.game_leaderboard_div_inner_score {
    font-weight: 600;
    font-size: 18px;
    color: #30F998;
    width: 100%;
    text-align: right;
    padding-right: 10px;
}


.game_leaderboard_div_inner_user {
    width: 40px;
    height: 40px !important;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}


/*****************  DESKTOP  *****************/

.game_D_Div {
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url(/static/media/desktop_back.06ee8727.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.game_D_Hero {
    min-height: calc(1vh * 72);
    min-height: calc(var(--vh, 1vh) * 72);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    position: relative;
    padding: 0 10vw 6vh;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.game_D_Hero h2 {
    font-weight: 700;
    font-size: 100px;
    line-height: 100%;
}

.game_D_Hero p {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
}

.game_D_Hero .Home_Tags p {
    font-weight: 500;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
}

.game_D_Bottom {
    background: #000000;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 50px 50px;
    padding: 10px 10vw 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.game_D_Stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
}

.game_D_Stat {
    color: white;
    text-align: center;
}

.game_D_Stat b {
    font-weight: 600;
    font-size: 24px;
}

.game_D_Stat h4 {
    font-weight: 400;
    font-size: 16px;
}

.game_D_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.game_D_Friends {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.game_D_Friends img {
    width: 50px;
    height: 50px;
    margin-left: -20px;
    border-radius: 50px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
}

.game_D_social p {
    color: white;
    font-size: 15px;
    line-height: 120%;
}

.game_D_Inside {
    padding: 60px 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.game_D_Inside_Leaderboard {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    padding: 40px;
    color: white;
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.game_D_Inside_Leaderboard h3 {
    font-weight: 500;
    font-size: 22px;
}

.game_D_Inside_Leaderboard_Table {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.game_D_Leaderboard_Div {
    padding: 14px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.game_D_Leaderboard_Div img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.game_D_Leaderboard_Div p {
    margin: 0;
}

.game_D_Leaderboard_First {
    background: linear-gradient(90deg, rgba(255, 184, 0, 0.5) 0%, rgba(255, 184, 0, 0) 100%);
}

.game_D_Leaderboard_Second {
    background: linear-gradient(90deg, rgba(168, 170, 175, 0.5) 0%, rgba(168, 170, 175, 0) 100%);
}

.game_D_Leaderboard_Third {
    background: linear-gradient(90deg, rgba(154, 120, 103, 0.5) 0%, rgba(154, 120, 103, 0) 100%);
}

.game_D_Leaderboard_Div h5 {
    font-size: 18px;
    font-weight: 600;
}

.game_D_Leaderboard_Div h6 {
    font-size: 16px;
    font-weight: 600;
    margin-left: auto;
}
.App {
  text-align: center;
}

.backgrounddiv_disable {
  left: -100vw;
  z-index: 999 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

i.icon.hidden {
  display: none;
}

#playButton {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform: scale(5, 5);
          transform: scale(5, 5);
}

.fullscreen {
  height: 100%;
}

.onboard_inner video {
  position: absolute;
  top: 20px;
  z-index: 1;
  width: 70%;
  object-fit: contain;
  height: auto;
  max-width: 390px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logobacking {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgb(250, 250, 250);
  border-radius: 50% 50%;
  width: 160px;
  height: 160px;
}

.play {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 64px;
  height: 64px;
  fill: black;
}

.stopped {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 44px;
  height: 44px;
  fill: #9e9e9e;
}

.logo {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 128px;
  height: 128px;
}
.logo path {
  stroke-dasharray: 315;
  stroke-dashoffset: 315;
  -webkit-animation: dash 1.5s alternate infinite;
          animation: dash 1.5s alternate infinite;
  stroke: #9e9e9e;
  stroke: width 1.2px;
  fill: white;
}

.logo g {
  -webkit-animation: fill-in 1.5s 1.5s alternate infinite;
          animation: fill-in 1.5s 1.5s alternate infinite;
  fill-opacity: 1;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes fill-in {
  to {
    fill-opacity: 1;
  }
}

@keyframes fill-in {
  to {
    fill-opacity: 1;
  }
}

.hidden {
  display: none !important;
}

.app_loading_div {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/PKBack2.ba0f4c35.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
} 
    
@-webkit-keyframes rotationBack {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
} 
    
@keyframes rotationBack {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

.app_loading_loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

.app_loading_div p {
  color: white;
  padding: 10px;
}
.str3mlogo {
    width: 250px;
  }
  
  
  .onboard_div {
    width: 100vw;
    height: 100svh;
    background-image: url(/static/media/grad_bckgrnd.86b69249.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 9999;
    display: flex;
    justify-content: center;
  }
  
  .onboard_div_disabled {
    display: none;
  }
  
  .onboard_inner {
    /* background-image: url('../assets/grad_bckgrnd.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    opacity: 0;
  }
  
  .ob_active {
    display: flex;
    opacity: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  
  .ob_image {
    width: 70%;
    padding-bottom: 10vh;
  }
  
  .onboard_bottom {
    background: #0A0A0C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    align-items: center;
    height: 100svh;
    max-height: 240px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 20px 40px;
    color: white;
    text-align: center;
  }
  
  .onboard_bottom h2 {
    font-size: 35px;
    line-height: 100%;
    margin: 0
  }
  
  .onboard_bottom p {
    font-size: 16px;
    line-height: 130%;
    padding-top: 10px;
    padding-bottom: 20px;
    margin: 0
  }
  
  .onboard_button {
    cursor: pointer;
    height: 50px;
    width: 100px;
    background: linear-gradient(91.32deg, #642B73 0%, #C6426E 100%);
    border-radius: 84px;
    border: none;
    color: white;
    position: relative;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  .onboard_button:hover {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}

  .onboard_button_back {
    cursor: pointer;
    height: 50px;
    width: 100px;
    background: transparent;
    border-radius: 84px;
    border: none;
    color: white;
    position: relative;

  }
  
  .ob_svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
  
  #ob_one {
    z-index: 10;
  }
  
  #ob_two {
    z-index: 9;
  }
  
  #ob_three {
    z-index: 8;
  }
  
  #ob_four {
    z-index: 7;
  }

  @media only screen and (max-width: 768px) {
    .onboard_bottom h2 {
      font-size: 28px;
    }
    .onboard_bottom p {
      font-size: 14px;
    }
  }
#launchContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#launchContainer > div {
  text-align: center;
}

* {
    margin: 0;
    padding: 0;
}

.userDiv {
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url(/static/media/grad_bckgrnd.86b69249.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.user_background {
    height: 230px;
    width: 102%;
    position: relative;
    z-index: 1;
    object-fit: cover;
    left: -2px;
}

.user_info {
    text-align: center;
    position: relative;
    width: 100%;
    z-index: 10;
    margin-top: -50px;

}

.user_profile {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.user_profile_online {
    width: 24px;
    height: 24px;
    border: 2px solid white;
    background: #30F998;
    position: absolute;
    right: 6px;
    bottom: 6px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.user_friend_profile_online {
    width: 20px;
    height: 20px;
    border: 2px solid white;
    background: #30F998;
    position: absolute;
    right: 0;
    bottom: 20px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.user_friend_profile_offline {
    width: 20px;
    height: 20px;
    border: 2px solid white;
    background: gray;
    position: absolute;
    right: 0;
    bottom: 20px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;

}

.user_info h3 {
    color: white;
    padding-top: 10px;
}

.user_info h6 {
    color: white;
    opacity: 0.8;
    font-weight: 300;
    font-size: 12px;
}

.user_info p {
    color: white;
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
    width: 80%;
    margin: auto;
}

.user_friends {
    display: flex;
    flex-direction: row;
    width: 100vw;
    grid-gap: 20px;
    gap: 20px;
    overflow-x: scroll;
    padding: 20px 10px;
}

.user_friends h6 {
    color: white;
    opacity: 0.8;
    font-weight: 300;
    font-size: 10px;
    text-align: center;
}

.user_friend {
    width: 76px;
    height: 76px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.userDiv .react-tabs__tab-list {
    padding-top: 10px;
    text-align: center;
    border: 0;
    display: flex;
    justify-content: space-around;
}

.userDiv .react-tabs__tab {
    color: white;
    opacity: 0.2;
    padding: 0;
    font-weight: 600;
    font-size: 12px;
}

.userDiv .react-tabs__tab:focus:after {
    background: #FCCA4A;
    height: 2px;
}

.userDiv .react-tabs__tab--selecte:focus:after {
    background: #FCCA4A;
    height: 2px;
}

.userDiv .react-tabs__tab--selected {
    color: #FCCA4A;
    opacity: 1;
    background: transparent;
    border: 0;
}

.react-tabs__tab-panel {
    padding: 20px 0 60px;
}

.user_favourites {
    display: flex;
    flex-direction: row;
    width: 100vw;
    grid-gap: 20px;
    gap: 20px;
    overflow-x: scroll;
    padding: 0 20px 50px;

}

.user_favourites_item {
    position: relative;
}

.user_favourites_text {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    padding: 18px 12px;
}

.user_favourites_text_tabs {
    display: flex;
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
    padding-bottom: 12px;

}

.user_favourites_text h6 {
    font-weight: 400;
    padding-top: 2px;
}

.user_favourites_text_tabs small {
    color: white;
    font-size: 10px;
    font-weight: bold;
    background: rgba(255,255,255,0.3);
    padding: 4px 10px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.user_favourites_img {
    width: 60vw;
    height: 312px;
    object-fit: cover;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.user_comingsoon {
    position: relative;
    width: 100%;
    height: 24vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_comingsoon h4 {
    color: white;
    padding-bottom: 1vh;
    z-index: 5;
    text-align: center;
    letter-spacing: 5.5px;
    text-transform: uppercase;
    font-weight: 800;
}

.user_favourites_comingsoon {
    width: 100%;
    position: absolute;
    z-index: 4;
}




/* DESKTOP */

.user_D_Div {
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
    width: 100%;
    background-image: url(/static/media/desktop_back.06ee8727.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    padding: 0 6vw;
}

.user_D_background {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.user_D_ProfileDiv { 
    grid-area: 2 / 1 / 4 / 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.user_D_ProfileDiv h2 {
    font-weight: 500;
    font-size: 24px;
    color: white;
    padding: 0;
    margin: 0;
    line-height: 100%;
}

.user_D_ProfileDiv small {
    font-weight: 300;
    font-size: 12px;
    color: white;
    opacity: 0.8;
}

.user_D_ProfileDiv p {
    padding-top: 10px;
    font-weight: 300;
    font-size: 14px;
    color: white;
    width: 90%;
    margin: 0px auto;
}

.user_D_profile {
    height: 100px;
    width: 100px;
    border: 4px solid #30F998;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
.user_D_FriendsDiv { 
    grid-area: 4 / 1 / 6 / 3;
    text-align: center;
}

.user_D_FriendsDiv h2 {
    font-weight: 500;
    font-size: 24px;
    color: white;
    padding: 0;
    margin: 0;
    line-height: 100%;
}

.user_D_FriendsDiv_Row {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
    padding-top: 16px;
}

.user_D_FriendsDiv_Row_Div {
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;

}

.user_D_FriendsDiv_Row_Div small {
    font-weight: 300;
    font-size: 12px;
    color: white;
    opacity: 0.8;
}

.user_D_FriendsDiv_Row_Div img {
    height: 60px;
    width: 60px;
    border: 0.5px solid #30F998;
    border-radius: 70px;
    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    -ms-border-radius: 70px;
    -o-border-radius: 70px;
}

.user_D_DashDiv { 
    grid-area: 2 / 3 / 6 / 7;
    padding: 30px 0 30px 30px;
}

.user_D_DashDiv h2 {
    font-weight: 500;
    font-size: 24px;
    color: white;
    padding: 0;
    margin: 0;
    line-height: 100%;
}

.user_D_DashDiv_Carousel {
    padding-top: 20px;
}

.user_D_DashDiv_SplideDiv {
    width: 22vw;
    aspect-ratio: 16 / 9;
    display: flex;
    flex-direction: column;
    background-position: center !important;
    background-size: cover !important;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 16px;
    color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.user_D_DashDiv_SplideDiv h3 {
    font-weight: 600;
    font-size: 18px;
}

.user_D_DashDiv_SplideDiv h4 {
    font-weight: 400;
    font-size: 12px;
}

.user_D_DashDiv_SplideDiv_Tags {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.user_D_DashDiv_SplideDiv_Tag {
    font-weight: 400;
    font-size: 10px;
    padding: 5px 12px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 23.9937px;
    letter-spacing: 0.6px;
}

.user_D_DashDiv_Activity {
    padding-top: 30px;
}

.user_D_DashDiv_ActivityRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
    padding-right: 30px;
    padding-top: 10px;
}

.user_D_DashDiv_ActivityRow_Div {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 100%;
    padding: 16px 22px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    position: relative;
}

.user_D_DashDiv_ActivityRow_Div img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 70px;
    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    -ms-border-radius: 70px;
    -o-border-radius: 70px;
}

.user_D_DashDiv_ActivityRow_Div_Right h3 {
    font-weight: 600;
    font-size: 14px;
}

.user_D_DashDiv_ActivityRow_Div_Right p {
    font-weight: 300;
    font-size: 12px;
}

.user_D_DashDiv_ActivityRow_Div b {
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    top: 10px;
    right: 18px;
    color: #FFB800;
}
* {
    margin: 0;
    padding: 0;
}

.studioDiv {
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url(/static/media/grad_bckgrnd.86b69249.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.studio_background {
    height: 44vh;
    width: 100%;
    position: relative;
    z-index: 9;
    object-fit: cover;
    object-position: bottom;
}

.studioBack {
    position: relative;
}

.studioBack .studioback_overlay {
    position: absolute;
    width: 100%;
    height: 99%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, #000000 100%);
    top: 0;
    left: 0;
    z-index: 10;
}

.studioBack_inner {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 12;
}

.studioBack_inner h2 {
    color: white;
    padding-bottom: 10px;
}

.studioBack_inner p {
  color: white;
  font-size: 14px;
  width: 80%;
  padding-bottom: 12px;
}

.studioBack_inner .svg {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.7;
  }
  
  .studioBack_inner .studio_div {
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
  }
  
  .studioBack_inner .studio_div .span {
    color: white;
    font-size: 12px;
  }
  
  .studioBack_inner .studio_div .studio {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px
  }
  
  .studioBack_inner .studio_div .studio img {
    width: 35px;
    height: 35px;
  }
  
  .studioBack_inner .studio_div .studio span {
    color: white;
    font-weight: 600;
  }

.studio_followbtn {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 51px;
    padding: 12px 20px;
    color: white;
    border: none;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.studio_bottom {
    background: black;
    padding: 20px 20px 30px;
    margin-top: -6px;
    position: relative;
    z-index: 20;
    border-radius: 0px 0px 30px 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.studio_stats {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.studio_stat {
    text-align: center;
}

.studio_stat b {
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.studio_stat h4 {
    color: white;
    font-weight: 400;
    font-size: 12px;

}

.studio_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;
}

.studio_socialfriends {
    padding: 0 10px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.studio_socialfriends img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    object-position: center;
    margin-left: -20px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.studio_social p {
    color: white;
    font-size: 14px;
}

.studio_carousel {
    position: relative;
    z-index: 9;
    padding: 35px 0 0;
    background-image: url(/static/media/grad_bckgrnd.86b69249.png);
    background-size: cover;
    background-repeat: no-repeat;
}

/*****************  DESKTOP  *****************/

.studio_D_Div {
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    background-image: url(/static/media/desktop_back.06ee8727.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.studio_D_Hero {
    min-height: calc(1vh * 72);
    min-height: calc(var(--vh, 1vh) * 72);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 13.42%, #000000 100%), url(/static/media/studio_back.9f8a9b70.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding: 0 10vw 6vh;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.studio_D_Hero h2 {
    font-weight: 700;
    font-size: 100px;
    line-height: 100%;
}

.studio_D_Hero p {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
}

.studio_D_Bottom {
    background: #000000;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 50px 50px;
    padding: 10px 10vw 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.studio_D_Stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
}

.studio_D_Stat {
    color: white;
    text-align: center;
}

.studio_D_Stat b {
    font-weight: 600;
    font-size: 24px;
}

.studio_D_Stat h4 {
    font-weight: 400;
    font-size: 16px;
}

.studio_D_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.studio_D_Friends {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.studio_D_Friends img {
    width: 50px;
    height: 50px;
    margin-left: -20px;
    border-radius: 50px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
}

.studio_D_social p {
    color: white;
    font-size: 15px;
    line-height: 120%;
}

.studio_D_Div .studio_D_DashDiv_SplideDiv {
    aspect-ratio: 3/2;
    width: 30vw;
    display: flex;
    flex-direction: column;
    background-position: center !important;
    background-size: cover !important;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 16px;
    color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.studio_D_Div .user_D_DashDiv_SplideDiv_Tag {
    margin: 0 4px 0 0 !important;
}

.Home_Tags_Follow {
    font-weight: 700 !important;
    padding: 15px 30px;
    border: none;
    color: white;
    font-size: 14px !important;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.Home_Tags_Follow:hover {
    background: rgba(255, 255, 255, 0.3);
}

.Home_Tags_Following {
    font-weight: 700 !important;
    padding: 15px 30px;
    border: none;
    font-size: 14px !important;
    background: rgba(255, 255, 255, 1);
    color: black;
    cursor: pointer;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
/* DESKTOP */

.signIn {
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background-image: url(/static/media/desktop_back.06ee8727.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.signin_back {
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 9;
}

.signIn_Inner {
    flex-direction: column;
    width: 98vw;
    max-width: 600px;
    text-align: center;
    grid-gap: 12px;
    gap: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.signIn_Logo {
    width: 200px;
}

.signIn_Inner p {
    font-weight: 400;
    font-size: 14px;
    color: white;
    padding-top: 40px;
    padding-bottom: 26px;
}

.signIn_btn {
    width: 350px;
    padding: 16px 32px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: row;
    cursor: pointer;
}

.signIn_btn span {
    font-weight: 600;
    font-size: 14px;
}

.google_btn {
    background: white;
    color: #181818 !important;
}

.apple_btn {
    background: #181818;
    color: white;
}

.twitter_btn {
    background: #1DA1F2;
    color: white;
}

.facebook_btn {
    background: #3B5998;
    color: white;
}

