@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
  touch-action: manipulation;

}

a {
  color: inherit;
}

a:hover {
  color: inherit;

}

h1, h2, h3, h4, h5 {
  margin: 0;
}

iframe {
  display: none !important;
}

body {
  background-image: url('./assets/grad_bckgrnd.png');
  background-position: center;
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: none;
  height: -webkit-fill-available;
  text-rendering: optimizeSpeed;
  position: relative;
  scroll-snap-type: y mandatory;
  place-items: center;
}

#gameroot {
  background-image: url('./assets/grad_bckgrnd.png');
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  z-index: 999;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

#div_background {
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

#root {
  height: 100svh;
  background: black;
  position: relative;
  left: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  z-index: 3;
}

#backround_root {
  height: 100svh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.gameroot_active {
  left: -100vw !important;
  z-index: -1 !important;
}

.user_logout {
  position: absolute;
  padding: 4px;
  top: 10px;
  right: 20px;
  z-index: 10;
  background: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

@media only screen and (max-width: 768px) {

  html {
    overflow-y: hidden;
  }

}